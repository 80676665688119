import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import Card from '../component/NewsSectionComponent';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function NewsSection() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Ensures animation happens only once
        threshold: 0.3,    // Trigger when 10% of the component is visible
      });

    const { md, lg } = useMediaQueries()        

    const [cardsData, setCardsData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiHandler('items/news?sort=-date_created');
                const cardDataWithImages = await Promise.all(data.map(async (card) => {
                    const imageUrl = await apiImageHandler(card.thumbnail);
                    return { ...card, imageUrl };
                }));
                setCardsData(cardDataWithImages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

  if(lg){
    return (
        <div className='w-[100vw] min-h-[130vh] pb-6 mt-[10vh] z-[7] relative' ref={ref}>
            <div className='relative z-[7]'>
                <div className='font-bold text-[72px] mx-[12vw]'>LATEST NEWS</div>
            </div>
    
            <div className='grid grid-cols-3 mx-[7vw] gap-4 mt-4'>
    
                {cardsData.map((card, index) => (
                    <Card 
                        key={index} 
                        image={card.imageUrl} 
                        title={card.title} 
                        description={card.subtitle} 
                        date_created={card.date_created}
                        id={card.id}
                    />
                ))}
            </div>
    
            {/* <div className='flex justify-center text-white hover:cursor-pointer'>
                <Link to={'/more-news'}><div className='mt-10 px-10 py-2 text-[18px] rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>  
            </div> */}
        </div>
      )
  }

  if(md){
    return (
        <div className='w-[100vw] pb-6 mt-4 z-[7] relative ' ref={ref}>
            <div className='relative z-[7]'>
                <div className='font-bold text-5xl text-center'>LATEST NEWS</div>
            </div>
    
            <div className='flex flex-col gap-4 mx-2'>
    
                {cardsData.map((card, index) => (
                    <Card 
                        key={index} 
                        image={card.imageUrl} 
                        title={card.title} 
                        description={card.subtitle} 
                        date_created={card.date_created}
                        id={card.id}
                    />
                ))}
            </div>
    
            {/* <div className='flex justify-center text-white hover:cursor-pointer'>
                <Link to={'/more-news'}><div className='mt-10 px-10 py-2 text-[18px] rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>  
            </div> */}
        </div>
      )
  }
}

export default NewsSection