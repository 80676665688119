import axios from 'axios';

async function apiHandler(url) {
    try {
        const response = await axios.get('https://admin.redyrahadian.com/' + url);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiImageHandler(id) {
    try {
        return "https://admin.redyrahadian.com/assets/" + id;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiDetailHandler(type, id) {
    try {
        return "https://admin.redyrahadian.com/items/"+ type + "_lists/"+ id;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

async function apiDetailNews(id) {
    try {
        const response = await axios.get('https://admin.redyrahadian.com/items/news/' + id);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export { apiHandler, apiImageHandler, apiDetailHandler, apiDetailNews };
