import React, { useEffect, useRef, useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import '../css/modal.css'
import '../css/card.css'
import '../css/curator.css'
import { useIpadMediaQueries, useMediaQueries } from '../hook';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import buttonKanan from '../../assets/button-kanan.png'
import buttonKiri from '../../assets/button-kiri.png'

export const CardAlt = ({ thumbnail1, thumbnail2, thumbnail3, thumbnail4, thumbnail5, title, content }) => {

  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [largeImage, setLargeImage] = useState(thumbnail1); // state untuk gambar besar
  const { md, lg } = useMediaQueries();

  const thumbnails = [thumbnail1, thumbnail2, thumbnail3, thumbnail4, thumbnail5].filter(Boolean); // Hanya menyimpan thumbnail yang ada
  const [currentIndex, setCurrentIndex] = useState(0); // index gambar saat ini

  const handleImageClick = (image, index) => {
    setLargeImage(image); // mengganti gambar besar saat gambar kecil diklik
    setCurrentIndex(index); // update index saat gambar kecil diklik
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % thumbnails.length; // looping ke gambar berikutnya
    setLargeImage(thumbnails[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length; // looping ke gambar sebelumnya
    setLargeImage(thumbnails[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  const customStyles = {
    width: '45%',
    height: '70%',
    padding: '2%',
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row"
  };

  const customStylesMd = {
    width: '90%',
    height: '70%',
    padding: '2%',
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row"
  };

  if (lg) {
    return (
      <div className='w-full my-4 flex justify-center items-center border-white border-b-2'>
        <div className='p-10 w-full'>
          <div className='flex align-center gap-4 justify-center'>
            <div className='flex flex-col justify-center items-center gap-4 w-[70%]'>
              <div className='w-full h-[390px] relative slider-image '>
                <img src={largeImage} className='object-cover w-full h-full'></img>
                {
                  thumbnails.length == 1 ? <></> : 
                    <div>
                      <div className='w-full h-[390px]  absolute top-0 background'></div>
                      <button onClick={handleNextImage} className='absolute left-5 top-[45%] text-white text-[2vw]'><img className='w-[30px] h-[30px] button-next' src={buttonKiri}></img></button>
                      <button onClick={handleNextImage} className='absolute right-5 top-[45%] text-white text-[2vw]'><img className='w-[30px] h-[30px] button-next' src={buttonKanan}></img></button>
                    </div>
                }
              </div>

              <div className='flex justify-between items-center gap-3 w-full '>
                {thumbnails.map((thumbnail, index) => (
                  <div className='w-[220px] h-[180px] ' key={index}>
                    <img className='w-full h-full object-cover cursor-pointer shadow-pop-tr' src={thumbnail} onClick={() => handleImageClick(thumbnail, index)}></img>
                  </div>
                ))}
                </div>
              </div>

            <div className='flex  flex-col items-start text-black w-full max-w-[30%] '>
              <div className='w-full font-bold text-[24px] text-white'>{title}</div>
              <div className='w-full h-auto  content text-left text-[14px] text-white'>{content}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (md) {
    return (
      <div className='w-[90vw] my-4 flex justify-center items-center border-white border-b-2'>
        <div className='m-4 w-full '>
          <div className='flex align-center gap-2 justify-center flex-col m-2'>
            <div className='flex flex-col justify-center items-center gap-3 h-full '>
              <div className='w-[100%] h-[80vw] relative  '>
                <div className='bg-[rgba(0,0,0,0.14)] w-[100%] h-[80vw] absolute z-5'></div>
                <img src={largeImage} className='object-cover w-full h-full'></img>
                {thumbnails.length == 1 ? <></> : 
                <div>
                <button onClick={handleNextImage} className='absolute left-2 top-[45%] text-white text-[2vw] '><img className='w-[6vw] h-[6vw]' src={buttonKiri}></img></button>
                
                <button onClick={handleNextImage} className='absolute right-2 top-[45%] text-white text-[2vw] '><img className='w-[6vw] h-[6vw]' src={buttonKanan}></img></button>
                </div>
                }
                </div>
              <div className='flex justify-between items-center gap-2 w-full h-full '>
                <div className='w-[30vw] h-[15vw] '>{thumbnail1 ? <img className='w-full h-full object-cover' src={thumbnail1} onClick={() => handleImageClick(thumbnail1)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail2 ? <img className='w-full h-full object-cover' src={thumbnail2} onClick={() => handleImageClick(thumbnail2)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail3 ? <img className='w-full h-full object-cover' src={thumbnail3} onClick={() => handleImageClick(thumbnail3)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail4 ? <img className='w-full h-full object-cover' src={thumbnail4} onClick={() => handleImageClick(thumbnail4)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail5 ? <img className='w-full h-full object-cover' src={thumbnail5} onClick={() => handleImageClick(thumbnail5)}></img> : <></>}</div>
              </div>
            </div>
              {/* Judul */}
              <div className='flex justify-center flex-col items-start text-black w-full'>
                <div className=' w-full font-bold text-[24px] text-white'>{title}</div>
                <div className=' w-full h-auto break-all content text-white'>{content}</div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const Card = ({ thumbnail, title, content }) => {
  const [open, setOpen] = useState(false);
  const { md, lg } = useMediaQueries()
  

  const onOpenModal = (e) => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };


  if (lg) {
    return (
      <div className='w-full flex flex-col justify-center items-center gap-3 border-white border-[1px] ' >
        <img 
          src={thumbnail} 
          className='w-full h-[15vw] object-cover cursor-pointer' 
          alt='thumbnail'
          onClick={onOpenModal}
        />
        <div className='p-4'>
          <div>
            <div className='mt-2 mb-2 font-bold text-[18px]'>{title}</div>
            <div className='content text-justify'>{content}</div>
          </div>
          <div className=' flex justify-start items-start w-full cursor-pointer'>
            <div className='bg-[black] text-white px-3 py-2  border-1 border-white text-[15px] fade-in' onClick={onOpenModal}>
                  Read More
            </div>
          </div>
        </div>
       

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeOnEsc={true} // Esc should close
          closeOnOverlayClick={true} // Clicking outside should close
        >
          <div className='flex items-center justify-center gap-4 '>
            <div className='w-[30vw] h-[30vw] flex items-center justify-center '>
              <img src={thumbnail} className='max-w-[30vw] max-h-[30vw] object-cover' alt='thumbnail'/>
            {/* <img src={thumbnail} className='h-full w-full object-cover' alt='thumbnail'/> */}
            </div>
            <div className='w-[50%] h-full '>
              <div className='modal-title font-bold text-3xl mb-2 '>{title}</div>
              <div className='modal-content text-justify text-[90%] break-words text-xl'>{content}</div>
            </div>
            
          </div>
        </Modal>
      </div>
    );
  }

  if (md) {
    return (
      <div className='flex items-center flex-col gap-4' >
        <div className='w-full max-h-[80vw] '>
          <img 
          src={thumbnail} 
          className='w-full h-full object-cover ' 
          alt='thumbnail'
          
        />
        </div>
        
        <div className=' '>
          <div className='mt-2 mb-2 font-bold text-[3.5vw]'>{title}</div>
          <div className='content text-[2.5vw]'>{content}</div>
        </div>
        <div className=' flex justify-start items-start w-full '>
          <div className='bg-[black] text-white px-3 py-2  border-1 border-white text-[3vw] ' onClick={onOpenModal}>
                Read More
          </div>
        </div>

        <Modal open={open} onClose={onCloseModal} center>
          <div className='flex items-center justify-center gap-4 flex-col'>
            <div className='max-w-[60vw] max-h-[60vw]  flex items-center justify-center '>
              <img src={thumbnail} className='max-w-[60vw] max-h-[60vw]' alt='thumbnail' />
              {/* <img src={thumbnail} className='h-full w-full object-contain' alt='thumbnail'/> */}
            </div>
            <div className='flex flex-col gap-2'>
              <div className='text-black font-bold text-[4vw]'>{title}</div>
              <div className='text-black text-justify text-[3vw]'>{content}</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};


export const BuilldingCard = ({ thumbnail, title, content }) => {
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleImageClick = () => {
    setModalContent({ thumbnail, title, content });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const customStyles = {
    width: '45%',
    height: '70%',
    padding: '2%',
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row"
  };

  const imageRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (imageRef.current && contentRef.current) {
      setTimeout(() => {
        contentRef.current.style.width = `${imageRef.current.clientWidth}px`;
      }, 5500)
    }
  }, [thumbnail]);

  return (
    <div className='building-card'>
      <img
        src={thumbnail}
        ref={imageRef}
        onClick={handleImageClick}
        alt='Building Thumbnail'
      />
      <div className='building-content' ref={contentRef}>
        <div className='mt-2 mb-2 font-bold text-[18px] text-white'>{title}</div>
        <div className='text-white'>{content}</div>
      </div>


      <Rodal visible={visible} onClose={hideModal} customStyles={customStyles}>
        <div className='flex'>
          <img src={modalContent.thumbnail} className='image-modal w-1/3 h-auto object-contain mr-20 ml-10' alt='thumbnail' />
          <div className='w-2/3'>
            <div className='modal-title font-bold text-[18px] mb-2 mt-20'>{modalContent.title}</div>
            <div className='modal-content text-justify'>{modalContent.content}</div>
          </div>
        </div>
      </Rodal>
    </div>
  );
};


export default Card;
