import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { apiHandler, apiImageHandler } from '../api/APIHandler';  
import { useIpadMediaQueries, useMediaQueries } from '../hook';
import { useInView } from 'react-intersection-observer';

const artTypes = [
  { type: 'product', label: 'Art Product' },
  { type: 'facade', label: 'Art Facade' },
  { type: 'sculpture', label: 'Art Sculpture' },
  { type: 'building', label: 'Art Building' }
];



function NextArtSection() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.7,    // Trigger when 10% of the component is visible
  });
  const { type } = useParams();
  const { md, lg } = useMediaQueries()
  const {ipad} = useIpadMediaQueries()
  // Filter out the current type
  const remainingTypes = artTypes.filter(artType => artType.type !== type);

  const [images, setImages] = useState({});
  
  const handleNavigation = (type) => {

    window.location.href = `/art/${type}`; // Mengubah URL dan otomatis reload halaman
  };

  const orderedTypes = ['sculpture', 'product', 'facade', 'building']; // Urutan yang kamu mau

useEffect(() => {
    const fetchImages = async () => {
        try {
            const data = await apiHandler('items/artworks_home');
            const loadedImages = {};

            // Mengisi loadedImages
            for (const artType of artTypes) { 
                const imageKey = `${artType.type}_bg`;  // Membuat key dinamis
                if (data[imageKey]) {
                    loadedImages[artType.type] = await apiImageHandler(data[imageKey]);
                }
            }

            // Urutkan loadedImages berdasarkan orderedTypes
            const sortedImages = {};
            orderedTypes.forEach((type) => {
                if (loadedImages[type]) {
                    sortedImages[type] = loadedImages[type];  // Hanya tambahkan yang ada
                }
            });

            setImages(sortedImages);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    fetchImages();
}, [remainingTypes]);

  // if(lg){
  //   return (
  //     <div className='pb-[200px] px-[10vw] pt-20 overflow-x-hidden '>
  //       <div className='font-bold text-[72px]'>Next Arts</div>
  
  //       <motion.div
  //         drag='x'
  //         dragConstraints={{ left: -500, right: 0 }}
  //         className='flex gap-10 mt-14 min-w-[120vw]'
  //       >
  //         {remainingTypes.map(({ type, label }) => (
  //           <div key={type} className='w-[60vw] h-[60vw] relative  '>
  //             <div className='absolute z-[1] w-full h-full'></div>
  //             <div className='absolute z-[2] w-full h-full opacity-[0] hover:opacity-[100] transition hover:cursor-pointer transition-opacity duration-[500ms] hover:bg-[#0009]'>
  //               <div className='relative h-full w-full'>
  //                 <div className='flex justify-center items-center w-full h-full text-[3vw] text-white'>{label}</div>
  //                 <div className='absolute bg-[white] z-[1] rounded-[50%] bottom-[50px] w-[7vw] h-[7vw] right-[40px]'>
  //                 <div 
  //                     onClick={() => handleNavigation(type)} 
  //                     className='w-full h-full flex items-center justify-center text-black hover:cursor-pointer'>
  //                       View Art
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <img src={images[type]} className='w-[100%] h-[100%] relative z-0' alt={`${label} image`} />
  //           </div>
  //         ))}
  //       </motion.div>
  //     </div>
  //   );
  // }

  // if(md){
  //   return (
  //     <div className=' pb-4 px-4 pt-4 overflow-x-hidden '>
  //       <div className='font-bold text-4xl'>Next Arts</div>
  
  //       <motion.div
  //         drag='x'
  //         dragConstraints={{ left: -100, right: 0 }}
  //         className='flex gap-4 mt-4 w-[120vw] '
  //       >
  //         {remainingTypes.map(({ type, label }) => (
  //           <div key={type} className= 'w-[60vw] h-[60vw] relative  object-fill'>
  //             <div className='absolute z-[2] w-full h-full opacity-[0] hover:opacity-[100] hover:cursor-pointer transition-opacity duration-[500ms] hover:bg-[#0009]'>
  //               <div className='relative h-full w-full'>
  //                 <div className='flex justify-center items-center w-full h-full text-[3vw] text-white'>{label}</div>
  //                 <div className='absolute bg-[white] z-[1] rounded-[50%] bottom-4 w-[7vw] h-[7vw] right-4'>
  //                 <div 
  //                     onClick={() => handleNavigation(type)} 
  //                     className='w-full h-full flex items-center justify-center text-black hover:cursor-pointer'>
  //                       View Art
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <img src={images[type]} className='w-[100%] h-[100%] relative z-0' alt={`${label} image`} />
  //           </div>
  //         ))}
  //       </motion.div>
  //     </div>
  //   );
  // }

  if(lg){
    return (
      <div className='w-[100vw] min-h-[100vh] pt-16 pb-10 z-[9] relative bg-white' ref={ref}>
         <div className='text-center font-bold text-[50px]'>NEXT ART</div>
          {
            ipad ? 
            <div>
              <motion.div className='flex mx-[100px] justify-center mt-4'
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 0.5 }} 
              >       
                {remainingTypes.map(({ type, label }) => (
                  <div className='relative bg-black'  onClick={() => handleNavigation(type)} >
                      <div className='absolute z-[2] text-[24px] text-red border-[4px] w-[90%] h-[92%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border-white  border-[2px]'>
                        <div className='h-full w-full text-[24px] justify-center flex items-end pb-12 text-white'>{label}</div>
                      </div>
                      <div className='w-full h-full transition duration-500 absolute hover:cursor-pointer'></div>
                      <div><img src={images[type]} className='h-[60vh] w-[300px] object-cover object-center border-1' alt={`${label} image`}/></div>
                    </div>
                ))}
              </motion.div>

            </div> : <motion.div className='flex mx-[100px] justify-center mt-4'
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5 }} 
          >       
            {remainingTypes.map(({ type, label }) => (
               <div className='relative bg-black'  onClick={() => handleNavigation(type)} >
                  <div className='absolute z-[2] text-[24px] text-red border-[4px] w-[90%] h-[92%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-[0] hover:opacity-[100] transition-opacity duration-500 cursor-pointer'>
                    <div className='h-full w-full text-[24px] justify-center flex items-end pb-12 text-white'>{label}</div>
                  </div>
                  <div className='w-full h-full transition duration-500 absolute hover:cursor-pointer'></div>
                  <div><img src={images[type]} className='h-[60vh] w-[300px] object-cover object-center border-1' alt={`${label} image`}/></div>
                </div>
            ))}
          </motion.div>
          }
          
  
          <div className='flex justify-center'>
            <Link to={'/more-art'}><div className='mt-10 px-14 py-2 rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>
          </div>
      </div>
    )
  }

  if (md) {
    return (
      <div className='w-[100vw] relative z-[9] bg-white' ref={ref}>
        <div className='mx-4'>
          <div className='text-center font-bold text-[10vw]'>NEXT ART</div>
          <div className='gap-4 flex flex-col'>
            {remainingTypes.map(({ type, label }) => (
              <div key={type} className='relative bg-black' onClick={() => handleNavigation(type)}>
                <div className='absolute z-[2] text-[24px] text-white w-[90%] h-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border-white  border-[2px]'>
                  <div className='h-full w-full text-[24px] flex justify-center items-end pb-12'>
                    {label}
                  </div>
                </div>
                <div className='w-[100%] h-[150vw] '>
                  <img src={images[type]} className='h-full w-full object-cover object-center border-1' alt={`${label} image`} />
                </div>
                
              </div>
            ))}
          </div>
          <div className='flex justify-center'>
            <Link to={'/more-art'}>
              <div className='mt-10 px-14 py-2 rounded-full custom-hover hover:cursor-pointer'>
                <span className='relative z-[200]'>View More</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  
}

export default NextArtSection;
