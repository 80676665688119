import React, { useEffect, useState } from 'react'
import { apiImageHandler, apiDetailNews } from '../api/APIHandler';
import { useMatch, useParams } from 'react-router-dom';
import { useMediaQueries } from '../hook';

function NewsDetailSection() {
    const { id } = useParams();
    const [news, setNews] = useState({})
    const { md, lg } = useMediaQueries()

    useEffect(() => {
        const fetchData = async () => {
            try {
              const data = await apiDetailNews(id);
              const imageUrl = await apiImageHandler(data.thumbnail);
              setNews({ ...data, imageUrl });

            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, [id]);

  if(lg){
    return (
      <div className='pt-[140px] px-[8vw] pb-20'>
          
          <div className='text-[48px] font-semibold mt-4'>{news.title}</div>
          <div className='w-full h-[100vh] mt-4'>
              <img src={news.imageUrl} className='w-full h-full object-cover object-center'  alt='photo'/>
          </div>
  
          <div className='mt-10 text-[18px]'>
              <div className='mt-4' dangerouslySetInnerHTML={{ __html: news.content }}></div>
          </div>
      </div>
    )
  }

  if(md){
    return (
      <div className='mt-4 px-4 pb-20 pt-[10vh]'>
          <div className='text-3xl font-semibold'>{news.title}</div>
          <div className='w-full h-[80vw] mt-4'>
              <img src={news.imageUrl} className='w-full h-full object-cover object-center'  alt='photo'/>
          </div>
  
          <div className='mt-4 text-sm'>
              <div className='mt-2' dangerouslySetInnerHTML={{ __html: news.content }}></div>
          </div>
      </div>
    )
  }
}

export default NewsDetailSection