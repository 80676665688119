import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';
import Marquee from 'react-fast-marquee';

function CuratorSection() {
    const {md,lg} = useMediaQueries()
    const { ref, inView } = useInView({
        triggerOnce: true, // Ensures animation happens only once
        threshold: 0.5,    // Trigger when 10% of the component is visible
      });
    const truncateMultilineStyle = {
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };

    const [photo1, setPhoto1] = useState('');
    const [photo2, setPhoto2] = useState('');
    const [photo3, setPhoto3] = useState('');
    const [photo4, setPhoto4] = useState('');
    const [photo5, setPhoto5] = useState('');
    const [data, setData] = useState({})

    useEffect(() => {
        // Fetch photo URL from an API
        const fetchPhoto = async () => {
            try {
                const data = await apiHandler('items/curators_home');
                setPhoto1(await apiImageHandler(data.curators_photo_one))
                setPhoto2(await apiImageHandler(data.curators_photo_two))
                setPhoto3(await apiImageHandler(data.curators_photo_three))
                const dataPhoto4 = data.curators_photo_four
                const dataPhoto5 = data.curators_photo_five
                dataPhoto4 ? setPhoto4(await apiImageHandler(data.curators_photo_four)) : setPhoto4(null)
                dataPhoto5 ? setPhoto5(await apiImageHandler(data.curators_photo_five)) : setPhoto5(null)
                setData(data);
            } catch (error) {
                console.error('Error fetching photo URL:', error);
            }
        };

        fetchPhoto();
      
    }, []);
  if(lg){
    return (

        // ini curator harusnya di bkin component, di loop, API nya dari one, two, three, jadi 1,2,3 
        <div className='w-[100vw] pt-16 relative z-[8] bg-white' ref={ref} >
            <motion.div
                initial={{ y: '-115vh' }}
                animate={inView ? { y: '0%' } : { y: '-115vh' }}
                transition={{ duration: 0.5 }}
                className='relative z-8'
            >
                <div className='relative z-8 '>
                    <div className='text-center font-bold text-[72px]'>CURATORS</div>
                    <div className='text-center text-[#9CA3AF]'>{data.curators_subtitle ? data.curators_subtitle : "Loading..."}</div>
                </div>
    
                <div 
                    className='flex justify-center mt-10 relative z-8 overflow-hidden'>
                    
                    <Marquee className='overflow-y-hidden'>
                    <div className='w-[25.5vw] h-[70vh] border-[#D9D9D9] border-1 mx-4'>
                        <div className='flex justify-center mt-6'><img className='w-[40%] h-[25vh] object-cover object-center' src={photo1} alt='photo_curator'/></div>
                        <div className=''>
                            <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_one ? data.curators_name_one : "Loading..."}</div>
                            <div className='text-center font-semibold'>{data.curators_position_one ? data.curators_position_one : "Loading..."}</div>
                        </div>
                        <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4 h-full' >“{data.curators_message_one ? data.curators_message_one : "Loading..."}”</div>
                    </div>


                    <div className='w-[25.5vw] h-[70vh] border-[#D9D9D9] border-1 mx-4'>

                        <div className='flex justify-center mt-6'><img className='w-[40%] h-[25vh] object-cover object-center' src={photo2} alt='photo_curator'/></div>
                        <div>
                            <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_two ? data.curators_name_two : "Loading..."}</div>
                            <div className='text-center font-semibold'>{data.curators_position_two ? data.curators_position_two : "Loading..."}</div>
                        </div>
                        <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4 h-full' >“{data.curators_message_two ? data.curators_message_two : "Loading..."}”</div>
                    </div>


                    <div className='w-[25.5vw] h-[70vh] border-[#D9D9D9] border-1 mx-4'>

                        <div className='flex justify-center mt-6'><img className='w-[40%] h-[25vh] object-cover object-center' src={photo3} alt='photo_curator'/></div>
                        <div>
                            <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_three ? data.curators_name_three : "Loading..."}</div>
                            <div className='text-center font-semibold'>{data.curators_position_three ? data.curators_position_three : "Loading..."}</div>
                        </div>
                        <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4 h-full' >“{data.curators_message_three ? data.curators_message_three : "Loading..."}”</div>
                    </div>


                    {
                        photo4 != null ? 
                        <div className='w-[25.5vw] h-[70vh] border-[#D9D9D9] border-1 mx-4'>

                            <div className='flex justify-center mt-6'><img className='w-[40%] h-[25vh] object-cover object-center' src={photo4} alt='photo_curator'/></div>
                            <div>
                                <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_four ? data.curators_name_four : "Loading..."}</div>
                                <div className='text-center font-semibold'>{data.curators_position_four ? data.curators_position_four : "Loading..."}</div>
                            </div>
                            <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4 h-full' >“{data.curators_message_four ? data.curators_message_four : "Loading..."}”</div>
                        </div> :
                        <></>
                    }

                    {
                        photo5 != null ? 
                        <div className='w-[25.5vw] h-[70vh] border-[#D9D9D9] border-1 mx-4'>

                            <div className='flex justify-center mt-6'><img className='w-[40%] h-[25vh] object-cover object-center' src={photo5} alt='photo_curator'/></div>
                            <div>
                                <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_five ? data.curators_name_five : "Loading..."}</div>
                                <div className='text-center font-semibold'>{data.curators_position_five ? data.curators_position_five : "Loading..."}</div>
                            </div>
                            <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4 h-full' >“{data.curators_message_five ? data.curators_message_five : "Loading..."}”</div>
                        </div> :
                        <></>
                    }
                    </Marquee>
    
                </div>
            </motion.div>
        </div>
      )
  }

  if(md){
    return (

        // ini curator harusnya di bkin component, di loop, API nya dari one, two, three, jadi 1,2,3 
        <div className='w-[100vw] z-[8] bg-white mt-4 flex flex-col items-center content-center ' ref={ref}>
           
                <div className='relative z-8 mx-2'>
                    <div className='text-center font-bold text-4xl'>CURATORS</div>
                    <div className='text-center text-[#9CA3AF] text-sm my-4'>{data.curators_subtitle ? data.curators_subtitle : "Loading..."}</div>
                </div>
    
                    
                    <div className='mx-3 flex flex-col items-center content-center gap-3'>
                        <div className='w-full min-h-[70vh] border-[#D9D9D9] border-1'>
    
                            <div className='flex justify-center mt-6'><img className='w-[60%] h-[25vh] object-cover object-center' src={photo1} alt='photo_curator'/></div>
                            <div>
                                <div className='text-center font-bold text-xl mt-3'>{data.curators_name_one ? data.curators_name_one : "Loading..."}</div>
                                <div className='text-center font-semibold text-lg'>{data.curators_position_one ? data.curators_position_one : "Loading..."}</div>
                            </div>
                            <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4 text-sm' style={truncateMultilineStyle}>“{data.curators_message_one ? data.curators_message_one : "Loading..."}”</div>
                        </div>


                        <div className='w-full min-h-[70vh] border-[#D9D9D9] border-1'>

                            <div className='flex justify-center mt-6'><img className='w-[60%] h-[25vh] object-cover object-center' src={photo2} alt='photo_curator'/></div>
                            <div>
                                <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_two ? data.curators_name_two : "Loading..."}</div>
                                <div className='text-center font-semibold'>{data.curators_position_two ? data.curators_position_two : "Loading..."}</div>
                            </div>
                            <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4' style={truncateMultilineStyle}>“{data.curators_message_two ? data.curators_message_two : "Loading..."}”</div>
                        </div>


                        <div className='w-full min-h-[70vh] border-[#D9D9D9] border-1'>

                            <div className='flex justify-center mt-6'><img className='w-[60%] h-[25vh] object-cover object-center' src={photo3} alt='photo_curator'/></div>
                            <div>
                                <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_three ? data.curators_name_three : "Loading..."}</div>
                                <div className='text-center font-semibold'>{data.curators_position_three ? data.curators_position_three : "Loading..."}</div>
                            </div>
                            <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4' style={truncateMultilineStyle}>“{data.curators_message_three ? data.curators_message_three : "Loading..."}”</div>
                        </div>

                        {
                        photo4 != null ? 
                        <div className='w-full min-h-[70vh] border-[#D9D9D9] border-1'>

                            <div className='flex justify-center mt-6'><img className='w-[60%] h-[25vh] object-cover object-center' src={photo4} alt='photo_curator'/></div>
                            <div>
                                <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_four ? data.curators_name_four : "Loading..."}</div>
                                <div className='text-center font-semibold'>{data.curators_position_four ? data.curators_position_four : "Loading..."}</div>
                            </div>
                            <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4' style={truncateMultilineStyle}>“{data.curators_message_four ? data.curators_message_four : "Loading..."}”</div>
                        </div> :
                        <></>
                        }

                        {
                            photo5 != null ? 
                            <div className='w-full min-h-[70vh] border-[#D9D9D9] border-1'>

                                <div className='flex justify-center mt-6'><img className='w-[60%] h-[25vh] object-cover object-center' src={photo5} alt='photo_curator'/></div>
                                <div>
                                    <div className='text-center font-bold text-[24px] mt-3'>{data.curators_name_five ? data.curators_name_five : "Loading..."}</div>
                                    <div className='text-center font-semibold'>{data.curators_position_five ? data.curators_position_five : "Loading..."}</div>
                                </div>
                                <div className='text-center text-[#666666] mt-2 mx-[12px] mb-4' style={truncateMultilineStyle}>“{data.curators_message_five ? data.curators_message_five : "Loading..."}”</div>
                            </div> :
                            <></>
                        }


                    </div>
    
            
        </div>
      )
  }
}

export default CuratorSection