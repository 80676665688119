import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./view/MainPage";
import LoadingPage, { LoadingPageAlt,LoadingPageSameLikeProduct } from "./view/LoadingPage";
import ViewMoreArtPage from "./view/ViewMoreArtPage";
import TypeDetailPage from "./view/TypeDetailPage";
import MoreNews from "./view/MoreNewsPage";
import NewsDetailPage from "./view/NewsDetailPage";
import ContactUs from "./view/ContactUsPage";
import TermOfService, { PrivacyPolicy } from "./view/TermOfServicePage";
import Exhibition from "./view/ExhibitionPage";

function App() {
  return (

    <BrowserRouter>
      <div style={{ display: "none" }}>some images are from unsplash.com</div>
      <Routes>
        <Route>
          <Route path='/' element={<LoadingPageSameLikeProduct />} />
          <Route path='/dashboard' element={<MainPage />} />
          <Route path='/more-art' element={<ViewMoreArtPage />} />
          <Route path='/art/:type' element={<TypeDetailPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/tos" element={<TermOfService />} />
          <Route path="/pp" element={<PrivacyPolicy />} />
          <Route path='/more-news' element={<MoreNews />} />
          <Route path='/news-detail/:id' element={<NewsDetailPage />} />
          <Route path='/exhibition' element={<Exhibition />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
